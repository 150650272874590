@tailwind base;
@tailwind components;
@tailwind utilities;

main{
    max-width: 1280px;
    margin: auto;
    min-height: 90vh;
    padding: 10px;
}
.header2{
    max-width: 1280px;
    margin: auto;
    min-height: 90vh;
   
}
